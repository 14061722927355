<template>
  <div class="main section" v-if="loaded">
    <div class="section-title" v-if="msgTitle == false">
      <div class="titleIcon"></div>
      <div class="title">财税风险</div>
      <!-- <BigPowerSwitcher v-model="showType"></BigPowerSwitcher> -->
    </div>
    <div class="head-level-title" v-else>
      <div class="head-level-0">企业财税风险概述</div>
      <div class="bottomDiv"></div>
    </div>
    <!-- <div v-if="showType === 'chart'"> -->
    <div class="stats-wrapper">
      <!-- <div class="time">检测区间：{{ data.period }}</div> -->
      <div class="stats">
        <div class="stats-item FirstItem" style="width: 21%">
          <div class="number">{{ data.total }}</div>
          <div class="text">检测总项</div>
        </div>
        <div class="stats-item">
          <div class="Items circularRed">
            <div class="backImg"><img src="../../../../assets/util/路径 9.png" alt="" /></div>
            <div class="number">{{ data.highRisk }}</div>
            <div class="text">高风险</div>
          </div>
        </div>
        <div class="stats-item">
          <div class="Items circularYellow">
            <div class="backImg"><img src="../../../../assets/util/路径 9.png" alt="" /></div>
            <div class="number">{{ data.middleRisk }}</div>
            <div class="text">中风险</div>
          </div>
        </div>
        <div class="stats-item">
          <div class="Items circularBlue">
            <div class="backImg"><img src="../../../../assets/util/路径 9.png" alt="" /></div>
            <div class="number">{{ data.lowRisk }}</div>
            <div class="text">低风险</div>
          </div>
        </div>
        <div class="stats-item">
          <div class="Items circularGreen">
            <div class="backImg"><img src="../../../../assets/util/路径 9.png" alt="" /></div>
            <div class="number">{{ data.noRisk }}</div>
            <div class="text">无风险</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ciyun-wrapper" style="display: none">
      <div class="toolbar">
        <BigPowerLegends
          name="风险词云"
          :legends="[
            { color: '#EA6C5B', text: '高风险' },
            { color: '#F7C122', text: '中风险' },
            { color: '#63DAAB', text: '低风险' },
          ]"
        ></BigPowerLegends>
      </div>
      <div class="section-body">
        <!--            <Danmu class="danmu" ref="danmu"></Danmu>-->
        <Ciyun
          legendsName="风险词云"
          class="danmu"
          ref="danmu"
          :data="data.riskWordCloudList"
          v-if="data.riskWordCloudList"
          @click="handleCiyunClick"
        ></Ciyun>
        <div class="danmu" v-else></div>
      </div>
    </div>
    <div class="graph">
      <div class="form-item">
        <div class="label">风险等级 :</div>
        <el-select
          v-model="filter.status"
          placeholder="请选择风险等级"
          filterable
          @change="loadFinancialRisk"
        >
          <el-option
            v-for="item in grade"
            :key="item.status"
            :label="item.name"
            :value="item.status"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- </div> -->
    <!-- <div v-if="showType === 'table'"> -->
    <div class="table-wrapper">
      <!-- <div class="time">检测区间：{{ data.period }}</div> -->
      <BigPowerTable
        :data="dataTable"
        v-if="dataTable"
        class="table"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
      ></BigPowerTable>
    </div>
    <!-- </div> -->
  </div>
  <div class="main section" style="padding-bottom: 0" v-else>
    <BigPowerLoading height="790px"></BigPowerLoading>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import BigPowerSwitcher from "@/pages/Result/ThreeYearResult/components/BigPowerSwitcher";
import BigPowerLegends from "@/pages/Result/ThreeYearResult/components/BigPowerLegends";
import { getAutoRoutePushTo } from "@/utils/helper";
import Ciyun from "@/pages/Result/ThreeYearResult/financialAnalysis/Ciyun";
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import { getFinancialRisk } from "@/api/threeYears";
import { mapState } from "vuex";

export default {
  name: "FinancialRisk",
  components: {
    BigPowerTable,
    BigPowerSwitcher,
    BigPowerLegends,
    Ciyun,
  },
  props: {
    msgTitle: Boolean,
  },
  async mounted() {
    this.loaded = false;
    // this.isLoading = false;
    await this.loadFinancialRisk();
    this.loaded = true;
    // this.isLoading = true;
  },
  data() {
    return {
      showType: "chart",
      data: {},
      isLoading: false,
      loaded: false,
      grade: [
        {
          status: 0,
          name: "全部",
        },
        {
          status: 1,
          name: "高风险",
        },
        {
          status: 2,
          name: "中风险",
        },
        {
          status: 3,
          name: "低风险",
        },
      ],
      filter: {
        status: 0,
      },
    };
  },
  methods: {
    handleCiyunClick(data) {
      this.$eventBus.$emit("switchToDetailTab", { anchor: data.id });
      //this.$router.push(getReportRoute(this.uuid,  {anchor: data.id}));
      //const fullURL = new URL(props.href, window.location.href).href;
      //location.href=fullURL;
    },
    async loadFinancialRisk(v) {
      console.log(v)
      this.isLoading = true;
      const res = await getFinancialRisk({
        programId: this.$route.params.uuid,
        grade: v ? v : "",
      });
      this.data = res.data.data;
      if (res.code == 200) {
        this.isLoading = false;
      }
    },
    // 搜索
    search() {
      // this.pageno = 1;
      // this.getprojectList();
    },
  },
  computed: {
    dataTable() {
      if (this.data.dataTable) {
        this.data.dataTable.body.forEach((row, index) => {
          if (row[2]) {
            if (row[2].content === "高") {
              row[2].color = "#F53F3F";
            } else if (row[2].content === "中") {
              row[2].color = "#FFB100";
            } else if (row[2].content === "低") {
              row[2].color = "#0096E6";
            }
          }
          if (row[3]) {
            if (row[3].content === "高") {
              row[3].color = "#F53F3F";
            } else if (row[3].content === "中") {
              row[3].color = "#FFB100";
            } else if (row[3].content === "低") {
              row[3].color = "#0096E6";
            }
          }
        });
      }

      return this.data.dataTable;
    },
  },
};
</script>

<style lang="stylus" scoped>
.section{
  background: #fff;
  width: 1100px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  box-sizing: border-box;
  padding:20px
}
.section-title
  height: 22px;
  color: #2663F6;
  line-height: 20px;
  display flex
  border-bottom 1px solid #e6e6e6;
  padding-bottom 18px

  .title
    font-size: 18px;
    font-weight: 600;
  .titleIcon
    width: 4px;
    height: 20px;
    background: #2663F6;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-right: 20px

.stats-wrapper
  background: #FFFFFF;
  //border: 1px solid #F2F3F5;
  margin 20px 0
  padding 20px

  .time
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    line-height: 20px;

  .stats
    display flex

    .stats-item
      width 25%;
      display flex;
      align-items center
      justify-content center
      flex-direction column
      //border-left 1px solid #F2F3F5;
      .Items
        width: 62%;
        height: 76%;

        border-radius:50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .number
          height: 48px;
          font-size: 42px;
          font-family: DIN Alternate-Bold, DIN Alternate;
          font-weight: bold;
          color: #FFFFFF;
          box-sizing: border-box;
          padding: 3px;
        .text
          color: #FFFFFF;
          height: 16px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium-, SourceHanSansCN-Medium;
          font-weight: normal;
          box-sizing: border-box;


      .number
        color: #1D2129;
        line-height: 42px;
        font-size: 42px;
        font-family: DIN Alternate-Bold, DIN Alternate;
        font-weight: bold;

      .text
        color: #4E5969;
        height: 16px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium-, SourceHanSansCN-Medium;
        font-weight: normal;

    .stats-item:first-child
      border-left 0 none
    .FirstItem
      width: 170px;
      height: 170px;
      background: #F1F6FE;
      opacity: 1;
      box-sizing: border-box;
      border-radius: 50%;
      margin: 0 25px;

      .number
       color: #2663F6;
       height: 63px;
      .text
       color: #2663F6;
       margin-top: -5px;


.ciyun-wrapper
  .toolbar
    display flex
    justify-content flex-end

.table-wrapper
  background: #FFFFFF;
  margin 20px 0
  margin-bottom 0



  .time {
    margin 20px 16px
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
  }
//表格样式
  .table {

    >>> .bigPowerTable {
      max-height 552px
      overflow auto
      border-bottom: 1px solid rgba(29,33,41,0.1);
    }
    >>>table.table th {
      background: #F1F6FE;
      padding: 13px 12px;
      font-weight: bold;
    }
    >>>table.table td{
      padding: 15px 12px;
      font-size:14px;
      color: #1D2129;
      font-weight: 500;
    }
    /* 滚动条thumb的背景色 */
    ::-webkit-scrollbar-thumb {
      width: 8px;
      background: #8D8F93;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
    }
  }
  .form-item {
    margin-right: 40px;
    display: flex;
    align-items: center;

    .label {
      white-space: nowrap;
      width: 69px;
      text-align: right;
      margin-right: 8px;
      font-size:14px;
    }

    display: flex;
  }
.stats-item{
  .circularRed{
    border:9px solid #FEEDE9;
    background: #F53F3F;
    position relative
    .backImg{
      position absolute
      top:31px;
    }
  }
  .circularYellow{
    border:9px solid #FFF7E5;
    background: #FFB100;
    position relative
    .backImg{
      position absolute
      top:31px;
    }
  }

  .circularBlue{
    border:9px solid #EBF7FD;
    background: #0096E6;
    position relative
    .backImg{
      position absolute
      top:31px;
    }
  }
  .circularGreen{
    border:9px solid #ebfdef;
    background: #2EB060;
    position relative
    .backImg{
      position absolute
      top:31px;
    }
  }


}
>>>.el-input__inner{
  width:240px;
  height:36px;
}
.head-level-0{
  width: 176px;
  height: 48px;
  background: #2663F6;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  padding: 12px 16px;
  box-sizing: border-box;
}
.bottomDiv{
  background: #2663f6;
  height:2px;
}
</style>
