<template>
  <div class="dali-style">
    <div class="page-wrapper" :key="actualZq">
      <div class="section-box">
        <div>
          <div class="tab-wrapper">
            <div class="uploadMethodText" @click="uploadDataPeriod">
              <div
                class="uploadMethodItem"
                data-period="0"
                :class="{ zqLocation: dataPeriod == 0 }"
              >
                {{ zaNameValue[0] }}
              </div>
              <div
                class="uploadMethodItem"
                data-period="1"
                :class="{ zqLocation: dataPeriod == 1 }"
              >
                {{ zaNameValue[1] }}
              </div>
              <div
                class="uploadMethodItem"
                data-period="2"
                :class="{ zqLocation: dataPeriod == 2 }"
              >
                {{ zaNameValue[2] }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="content-body"
          :class="{ nomargin: uploadMethod == 1, noPadding: haveUploads }"
        >
          <div>
            <div class="fix-flex" v-show="haveUploads == false">
              <div v-if="dataPeriod == 0">{{ zaNameValue[0] }}报表上传</div>
              <div v-if="dataPeriod == 1">{{ zaNameValue[1] }}报表上传</div>
              <div class="fix-flexs">
                <div class="acknowlege" @click="acknowledgeDialogVisible = true">
                  查看上传须知
                </div>
                <a
                  href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.zip"
                  class="btn-download fix-flexs1"
                  download
                  >下载模板</a
                >
              </div>
            </div>

            <div :class="{ 'upload-text': haveUploads }">
              <div
                class="fix-flexsL"
                :class="{
                  'fix-flexsLr': haveUploads == false,
                  'flex-max-widh': haveUploads,
                }"
              >
                <div class="fix-flexs drog_texts" v-if="haveUploads">
                  <div class="acknowlege" @click="acknowledgeDialogVisible = true">
                    查看上传须知
                  </div>
                  <a
                    href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.zip"
                    class="btn-download fix-flexs1"
                    download
                    >下载模板</a
                  >
                </div>
                <uploader
                  @showDetailBox="showDetailBox"
                  :checkHand="haveUploads"
                  @onUpload="onUpload"
                  :uuid="uuid"
                  v-if="actualZq"
                  :tag="version"
                  @changeLength="changeLength"
                  @overUpload="overUpload"
                ></uploader>
              </div>

              <table-list
                v-show="haveUploads"
                :tables="tables"
                @listChanged="listChanged"
                manner="upload"
                class="table-list fix-flexsR"
                :class="{ 'table-lists': haveUploads }"
                @showDetailBalance="showDetailBalance"
                :uuid="uuid"
                :zqLocations="zqLocation"
              ></table-list>
            </div>
          </div>
        </div>
      </div>
      <!--温馨提示弹出框-->
      <el-dialog
        :visible.sync="tipDialogVisible"
        width="576px"
        :show-close="false"
        class="tipDialog"
        top="32vh"
      >
        <div class="inner">
          <img src="@/assets/test/tip.png" class="img" />
          <div class="right-wrapper">
            <div class="title">温馨提示</div>
            <div class="desc">
              为保证数据准确提取，需尽量保证文件命名标准，若一个Excel内包含多个sheet则sheet页命名需规范
            </div>
            <button class="btn btn-primary btn-i-get" @click="handleTipDialogBtnClick">
              知道了
            </button>
          </div>
        </div>
      </el-dialog>
      <!--温馨提示弹出框-->
      <!--上传错误提示弹出框-->
      <el-dialog
        :visible.sync="correctDialogVisible"
        width="80%"
        :show-close="true"
        class="correctDialog"
        top="1vh"
        @close="handleCorrectDialogClose"
      >
        <SectionTitle>报表匹配</SectionTitle>
        <div class="modify-table-wrapper">
          <modify-table></modify-table>
        </div>
      </el-dialog>
      <!--/上传错误提示弹出框-->
      <!--纠错弹出框-->
      <el-dialog
        :visible.sync="uploadErrorDialogVisible"
        width="800px"
        :show-close="true"
        top="25vh"
        title="抱歉,以下表无法解析"
      >
        <el-table :data="uploadErrors" style="width: 100%">
          <el-table-column prop="userFileName" label="表名"></el-table-column>
          <el-table-column prop="reason" label="异常原因"></el-table-column>
        </el-table>
      </el-dialog>
      <!--/纠错弹出框-->
      <!--上传须知提示弹出框-->
      <el-dialog
        :visible.sync="acknowledgeDialogVisible"
        width="62.5%"
        :show-close="true"
        class="correctDialog"
      >
        <SectionTitle>上传须知</SectionTitle>
        <div class="modify-table-wrapper">
          <acknowledage-tab
            v-on:acknowledgeDialogVisible="clickbtn"
            :bankId="bankId"
          ></acknowledage-tab>
        </div>
      </el-dialog>
      <!--/上传须知提示弹出框-->

      <!--数据上传组件-->
      <TestDataUploader
        ref="testDataUploader"
        :bankId="bankId"
        :productID="productID"
        :productName="productName"
        :uuid="uuid"
        :zq="zq"
        :version="version"
        :deleteDataType="deleteDataType"
      ></TestDataUploader>
      <!--/数据上传组件-->

      <loading
        :active.sync="isLoading"
        backgroundColor="#fff"
        :can-cancel="true"
        :is-full-page="fullPage"
        loader="Dots"
        color="#2f54eb"
      ></loading>

      <licen ref="opendialog"></licen>
      <div v-if="showTables" class="containertables">
        <div class="tables">
          <div class="title">{{ balanceName }}</div>
          <div class="closes" @click="deleteTable">×</div>
          <div class="content">
            <el-table
              :data="tableData"
              style="width: 100%"
              height="620"
              row-key="subBsDm"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column
                prop="subBsDm"
                label="科目编码"
                width="160"
              ></el-table-column>
              <el-table-column
                prop="subBsMc"
                label="科目名称"
                width="150"
              ></el-table-column>
              <el-table-column label="年初余额" align="center">
                <el-table-column
                  prop="ncBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="ncLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="期初余额" align="center">
                <el-table-column
                  prop="beforeBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="beforeLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="本期发生额" align="center">
                <el-table-column
                  prop="currentBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="currentLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="本年累计" align="center">
                <el-table-column
                  prop="bnljBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="bnljLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="期末余额" align="center">
                <el-table-column
                  prop="afterBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="afterLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="progressUpload" v-if="progressUpload">
        <div class="progressBox">
          <img
            class="undraw_upload"
            src="@/assets/result/undraw_upload_87y9@2x.png"
            alt
          />
          <el-progress
            type="circle"
            :percentage="progressUploadStatus"
            :width="286"
            :status="processTexted ? 'success' : null"
          ></el-progress>
          <div class="processText" v-if="processTexting">上传中...</div>
          <div class="processTexted processTextedNo" v-if="processTexted">上传成功</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  getInfoByCompanyNsrsbm,
  useAuthorize,
  checkCompany,
  checkNew,
  breezeOuter,
  showBalanceDetail,
  genTestReport,
  addReportValue,
  isFindNeedExcel,
  getTaxAreaBureauServiceInfo,
  getCompanyId,
  getbasicinfo,
  isFindNeedExcel2,
  isFindNeedExcel3,
  zjBreezeLoginCreateTask,
} from "@/api/api";
import { loadTableData } from "@/api/table";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import Uploader from "./components/Uploader";
import TableList from "./components/TableList";
// import toTip from "./components/totip";
import ModifyTable from "./components/ModifyTable";
import SectionTitle from "@/components/SectionTitle";
import AcknowledageTab from "./components/AcknowledageTab";

import ProgressDiagram from "@/pages/components/ProgressDiagram";

import TestDataUploader from "./components/TestDataUploader";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";
import { zq2name, previousZq, previousZq2, zq2obj } from "@/utils/helper";

import licen from "./components/licen-dialog";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { getLocalAbilities } from "@/utils/abilities";

export default {
  data() {
    return {
      thirdDataSource: "", //山东取数的方式
      requiredTables: [],
      isAutoDataNum: 0,
      zjhm: "",
      czydm: "",
      zaNameValue: [],
      nameListArr: null,
      nameInfoList: "",
      identityValue: "",
      jsmobileCode: "",
      jscookies: "",
      jsinfo: "",
      dialogVisible: false,
      isloaded: false,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      uploadMethod: sessionStorage.getItem("method") || 1,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      haveUploads: true,
      showTables: false,
      balanceName: "",
      tableData: [],
      taxSiteUsername: this.$store.state.companyInfo.nsrsbh || "",
      batchNo: "",
      isEnableAutoGetData: true, // 汇算清缴是否已经结束
      isTaxAutoProcessing: false, //自动取数进行中
      isVersionList: [9, 11, 15],
      baseVersion: 12, //房地产tag 需要基础信息表
      checkBaseInfoData: "",
      deleteDataType: this.$route.query.deleteDataType,
      addreportAndCheck: false,
      //
      zq: this.$route.query.zq,
      bankId: this.$route.query.bankId,
      version: this.$route.query.version,
      uuid: this.$route.params.uuid,
      dataPeriod: sessionStorage.getItem("dataPeriod") || 0,
      zqLocation: this.$route.query.zq,
      // 以上参数都用前面的路由传入，目前先写死
    };
  },
  components: {
    Loading,
    BreadCrumb,
    Uploader,
    TableList,
    SectionTitle,
    ModifyTable,
    AcknowledageTab,
    ProgressDiagram,
    TestDataUploader,
    licen,
    // toTip,
  },
  props: {
    // zq: [Number, String],
    productName: String,
    productID: [Number, String],
    // zqLocation: [Number, String],
    // bankId: String,
    // uuid: String,
    // dataPeriod: [Number, String],
    // version: [Number, String],
    isShanDong: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),

    correctDialogVisible: {
      get: function () {
        return this.$store.state.UploadTable.correctDialogVisible;
      },
      set: function (value) {
        this.setCorrectDialogVisible(value);
      },
    },
    zqName: function () {
      return zq2name(this.actualZq);
    },
    progress: function () {
      let ret = 2;
      if (this.dataPeriod == 0) {
        ret = 2;
      } else if (this.dataPeriod == 1) {
        ret = 3;
      }
      return ret;
    },
    SDStables() {
      let ret = [];
      if (this.tables.所得税表) {
        ret = this.tables.所得税表.list;
      }
      return ret;
    },
    tablesList() {
      const ret = [];
      if (!this.tables) {
        return ret;
      }

      for (const tableGrp of Object.values(this.tables)) {
        for (const table of tableGrp.list) {
          ret.push(table);
        }
      }
      return ret;
    },
  },

  async mounted() {
    this.isloaded = false;
    localStorage.removeItem("policy");
    // const nsrsbm = JSON.parse(localStorage.getItem("user")).nsrsbm;

    await this.init();
    //是否已经完成汇算清缴

    this.isloaded = true;

    this.zqNameValue();

    const method = sessionStorage.getItem("method");
    if (method == 2) {
      // this.switchToManualUploader();
    }
  },
  methods: {
    //
    uploadDataPeriod(e) {
      let period = e.target.dataset.period;
      if (!period) return;
      if (period == this.dataPeriod) return;
      this.dataPeriod = period;
      sessionStorage.setItem("dataPeriod", this.dataPeriod);
      this.init();
    },
    //
    clearBrowserNavigatorHistory() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, document.URL);
      });
    },
    async loadRequiredTables() {
      const res = await isFindNeedExcel3(
        this.uuid,
        this.zq,
        this.version,
        this.$store.state.companyInfo.nsrsbm,
        this.$store.state.companyInfo.nsrsbh
      );
      this.requiredTables = res.data;
    },
    getLocalAbilities(tag) {
      return getLocalAbilities(tag);
    },

    zqNameValue() {
      let zaNameValue = [];
      let zqLast = this.zqLocation.substr(this.zqLocation.length - 1, 1);

      if (zqLast == 1) {
        let nextMonth = "";
        let nextMonth2 = "";
        let nextYear = "";
        let nextYear2 = "";
        if (this.zqLocation.slice(4, 6) == "01") {
          nextMonth = "12";
          nextMonth2 = "11";
          nextYear = Number(this.zqLocation.slice(0, 4)) - 1;
          nextYear2 = Number(this.zqLocation.slice(0, 4)) - 1;
        } else if (this.zqLocation.slice(4, 6) == "02") {
          nextMonth = "01";
          nextMonth2 = "12";
          nextYear = Number(this.zqLocation.slice(0, 4));
          nextYear2 = Number(this.zqLocation.slice(0, 4)) - 1;
        } else {
          nextMonth = (Number(this.zqLocation.slice(4, 6)) - 1).toString();
          nextMonth2 = (Number(this.zqLocation.slice(4, 6)) - 2).toString();
          nextYear = this.zqLocation.slice(0, 4);
          nextYear2 = this.zqLocation.slice(0, 4);
        }
        if (nextMonth.length == 1) {
          nextMonth = "0" + nextMonth;
        }
        zaNameValue[0] =
          this.zqLocation.slice(0, 4) + "年" + this.zqLocation.slice(4, 6) + "月";
        zaNameValue[1] = nextYear + "年" + nextMonth + "月";
        zaNameValue[2] = nextYear2 + "年" + nextMonth2 + "月";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 4) {
        let nextYear = this.zqLocation.slice(0, 4);
        zaNameValue[0] = this.zqLocation.slice(0, 4) + "年";
        zaNameValue[1] = Number(nextYear) - 1 + "年";
        zaNameValue[2] = Number(nextYear) - 2 + "年";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 2) {
        let year = this.zq.slice(0, 4);
        let quer = this.zq.slice(5, 6);
        let nextQuer;
        let nextQuer2;
        let nextYear;
        let nextYear2;
        if (quer == 1) {
          nextYear = year - 1;
          nextYear2 = year - 1;
          nextQuer = 4;
          nextQuer2 = 3;
        } else if (quer == 2) {
          nextYear = year;
          nextYear2 = year - 1;
          nextQuer = 1;
          nextQuer2 = 4;
        } else {
          nextYear = year;
          nextQuer = quer;

          nextQuer = quer - 1;

          nextYear2 = year;
          nextQuer2 = quer - 2;
        }

        zaNameValue[0] = year + "年" + quer + "季度";
        zaNameValue[1] = nextYear + "年" + nextQuer + "季度";
        zaNameValue[2] = nextYear2 + "年" + nextQuer2 + "季度";
        this.zaNameValue = zaNameValue;
      }
    },

    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),
    //不能自动取数的提示
    overUpload() {
      this.progressUploadStatus = 100;
      this.processTexted = true;
      this.processTexting = false;
      setTimeout(() => {
        this.progressUpload = false;
        this.processTexted = false;
        this.processTexting = true;
      }, 1000);
    },

    changeLength(val) {
      this.progressUpload = true;
      this.progressUploadStatus = Number(((val.status / val.length) * 100).toFixed(2));
      if (this.progressUploadStatus >= 100) {
        this.progressUploadStatus = 100;
      }
    },
    // 查看余额表
    showDetailBalance(id, zq) {
      let token = JSON.parse(localStorage.getItem("token")).token;
      loadTableData(zq, id, token, this.uuid).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data.dataJson;
      });
      this.balanceName = this.zaNameValue[this.dataPeriod] + "余额表";
      this.detailZq = zq;
      this.showTables = true;
    },
    // 关闭查看余额表
    deleteTable() {
      this.showTables = false;
    },

    showDetailBox() {
      this.haveUploads = true;
    },

    // 打开授权协议
    openlicense() {
      this.$refs.opendialog.openDialog();
    },

    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
    },

    switchToManualUploader() {
      this.clearBrowserNavigatorHistory();
      this.loadRequiredTables();
      this.uploadMethod = 2;
      this.haveUploads = true;
      sessionStorage.setItem("method", this.uploadMethod);
    },

    handleShowAllSDSTablesBtnClick() {
      this.$store.commit("UploadTable/setSDSSelection", {
        SDSlist: this.tables.所得税表.list,
      });
    },
    handleSDSDialogSubmit() {
      this.showSDSDialog = false;
    },
    //第一阶段显示所得税填报选择
    showSDSFilterDialog() {
      if (this.SDSSelection === null) {
        // this.showSDSDialog = true;
      }
    },
    async checkBaseToNext() {
      //跳到下一期
      this.dataPeriod = 1;
      this.init();
    },
    handleBtnNext1() {
      this.dataPeriod = 0;
      this.init();
    },
    //自动取数上一步
    handlePreviousClick() {
      const params = {
        productID: this.productID,
        // bankId: this.bankId,
        productName: this.productName,
        uuid: this.uuid,
        version: this.version,
        isShanDong: this.isShanDong,
      };
      this.$router.push({
        name: "choose-table",
        params,
      });
    },
    //本期的下一步按钮
    handleBtnNext() {
      if (this.version == this.baseVersion) {
        let params = {
          nsrsbm: this.$store.state.companyInfo.nsrsbm,
        };
        getInfoByCompanyNsrsbm(params).then((res) => {
          this.checkBaseInfoData = res.data;
          if (!this.checkBaseInfoData || !this.checkBaseInfoData.projectName) {
            this.dialogVisible = true;
          } else {
            this.checkBaseToNext();
          }
        });
      } else {
        this.checkBaseToNext();
      }
    },

    async init() {
      //如果没有permission id就退出

      this.isLoading = true;
      console.log(this.zq);
      console.log(this.dataPeriod);

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      } else if (this.dataPeriod == 2) {
        this.actualZq = previousZq2(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      }

      this.$store.commit("UploadTable/setZq", { zq: this.actualZq });
      this.$store.commit("UploadTable/setZqList", { zq: this.zq });

      // await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      this.$store.commit("UploadTable/setProduct", {
        name: this.productName,
        id: this.productID,
        bankID: this.bankId,
      });

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },

    clickbtn() {
      this.acknowledgeDialogVisible = false;
    },
    handleTipDialogBtnClick() {
      this.tipDialogVisible = false;
      localStorage.setItem("hideWenxinTip", true);
    },
    listChanged() {
      this.loadList();
    },

    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      //bankId, qykjzd, nsrsbh, zq
      const tables = await getUserTables(
        this.bankId,
        this.version,
        this.companyInfo.nsrsbm,
        this.actualZq,
        this.uuid,
        false
      );

      // 如果有上传的数据就展开表格

      if (tables.data && tables.msgCode != -1) {
        for (let i in tables.data) {
          // console.log(i);
          if (tables.data[i].uploadNum > 0) {
            this.haveUploads = true;
          }
        }
      }

      // 报税版隐藏余额表
      if (this.version == 3 && tables.data) {
        for (let i in tables.data) {
          if (i == "余额表") {
            delete tables.data[i];
          }
        }
      }

      //过滤未上传的表格
      this.tables = {};
      let i = 0;
      for (const grpName in tables.data) {
        i++;
        const grpData = tables.data[grpName];
        const newGrp = [];

        for (const table of grpData.tableInfo) {
          i++;
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
            zq,
            children,
          } = table;

          //整理一下children的格式
          const thechildren = children.map((child) => {
            i++;
            const {
              tableNameCn,
              tableNameEn: templateId,
              uploadStatus: status,
              tableNum,
              zq,
              children,
            } = child;
            return {
              name: `${tableNameCn}`,
              status,
              zq,
              templateId,
              tableNum,
              children,
              id: i,
            };
          });
          i++;
          const item = {
            name: `${tableNameCn}`,
            status,
            zq,
            templateId,
            tableNum,
            id: i,
            children: thechildren,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }

      if (Object.keys(this.tables).length > 0) {
        this.hasItemsInList = true;
      } else {
        this.hasItemsInList = false;
      }
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.uploadStatus;
          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data = {};
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }
      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
      this.$emit("getNewTableData");
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
    },
  },
  // destroyed() {

  // },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.tip{
  margin 0 48px  !important
  width auto !important
}

.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.content-body {
  margin: 20px;
  background: #fff;
  border-radius: 8px;
  padding: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 24px;
}

.section-box{
  margin-top: 0;
  box-shadow: 0px 0px 0px 0px;
  padding 0
  .title {
    color: rgba(0, 0, 0, 0.8);
    padding 16px 24px
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #eeeeee;
    text-align left

    .tips {
      font-size: 14px;
      color: #f5222d;
      margin-left: 16px;
    }
  }
}
.page-wrapper {
  padding-bottom: 84px;
}

.nomargin {
  margin: 0 30px;
  padding: 0;
}


>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.tab-wrapper{
  // border-bottom 1px solid #d9d9d9;
}
.uploadMethodText {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 9px;
  align-items center
  justify-content center
  margin-bottom: 8px;
  padding-left: 179px;
}

.uploadMethodItem {
  width: 77px;
  height: 32px;
  line-height: 32px;
  background: #FFFFFF;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  color: #86909C;
  cursor: pointer;
  margin-right: 20px;
}

.uploadMethodItem:last-child {
  margin-right: 0;
}

.zqLocation {
  background: rgba(22,93,255,0.1);
  border-radius: 2px;
  font-weight: 600;
  color: #165DFF;
}

.fix-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.fix-flexs {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #165DFF;
}

.fix-flexs1 {
  margin-left: 24px;
}

.drog_texts {
  padding-top: 10px;
  padding-bottom: 14px;
  width: 304px;
}

.upload-text {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-sizing border-box
}

.table-lists {
  flex: 1;
  margin-top: 0;
  padding-top: 0px;
}


// 余额表
.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -250px;
  width: 1158px;
  // height: 600px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 4000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.fix-flexsL {
  width: 25%;
  padding-right: 32px;
}

.fix-flexsR {
  width: 75%;
}

.fix-flexsLr {
  width: 100%;
  // max-width: auto;
}

.flex-max-widh {
  max-width: 304px;
}

.totip {
  position: absolute;
  left: 390px;
  top: 8px;
}

.noPadding {
  padding-right: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}
</style>
