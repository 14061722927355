<template>
  <div>
    <div class="menu-result" v-show="loaded">
      <div style="position: fixed;">
        <div class="updownItem">
          <el-tooltip effect="dark" content="收起目录" placement="right-start" popper-class="box-item">
            <div class="upDown" v-show="leftShow" @click="leftShowClick">
              <i class="iconfont iconicon_arrow_line_left"></i>
            </div>
          </el-tooltip>
          <el-tooltip effect="dark" content="展开目录" placement="right-start" popper-class="box-item">
            <div class="upDownRight" v-show="rightShow" @click="rightShowClick">
              <i class="iconfont iconicon_arrow_xx_right"></i>
            </div>
          </el-tooltip>
        </div>
        <div style="background: #fff;padding:8px;overflow: auto;height: calc(100vh - 72px)" v-if="isShowMenu">
          <div class="mulu">目录</div>
          <MenuTree class="menuTree" :data="treeData" target="treeData" v-if="isShowMenu" :key="0"
          style="top: 56px; "></MenuTree>
        </div>
        
      </div>

      <resultFinance class="resultFinance" :anchor="anchor" :hide-share="true" @load="handleReportLoad"></resultFinance>
    </div>
    <div v-show="!loaded">
      <BigPowerLoading height="790px"></BigPowerLoading>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MenuTree from "@/pages/ResultEditor/components/MenuTree";
import resultFinance from "@/pages/Result/Result";

export default {
  props: {
    anchor: [String, Number],
  },
  computed: {
    ...mapState("ResultEditor", ["treeData"]),
  },
  components: {
    resultFinance,
    MenuTree,
  },
  data() {
    return {
      loaded: false,
      leftShow: true,
      rightShow: false,
      isShowMenu: true,
    };
  },
  methods: {
    leftShowClick() {
      this.leftShow = false;
      this.rightShow = true;
      this.isShowMenu = false;
    },
    rightShowClick() {
      this.leftShow = true;
      this.rightShow = false;
      this.isShowMenu = true;
    },
    handleReportLoad() {
      this.loaded = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.mulu{
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #1d2129;
  line-height: 22px;
  border-bottom: 1px solid #dedede;
  padding: 8px 0 15px;
  display: flex;
  margin: 0 8px 5px;
  align-items: center;
  justify-content: space-between;
}
.upDownRight >>> .iconfont {
  position absolute;
  top: 14px;
}

.upDownRight:hover >>> .iconfont {
  color: #2663F6
}

.upDownRight {
  width: 16px;
  height: 48px;
  background: #fff;
  //z-index: 1;
  position: fixed;
  left: 2px;
  border-radius: 0px 16px 16px 0px;
  top: 341px;
  cursor: pointer;
}

.upDown {
  width: 16px;
  height: 48px;
  background: #fff;
  //z-index: 1;
  position: fixed;
  left: 324px;
  border-radius: 0px 16px 16px 0px;
  top: 341px;
  cursor: pointer;
}

.upDown >>> .iconfont {
  position absolute;
  top: 14px;
}

.upDown:hover >>> .iconfont {
  color: #2663F6
}
.menu-result {
  width:100%;
  display: flex;
  //justify-content: center;
  //min-width: 1500px;

  .menuTree {
    //position: fixed;
    top: 120px;
    left: 0;
    width: 300px;
    //overflow: auto;
    height: calc(100vh - 130px);
    background: #fff;
    z-index: 200;
  }

  .resultFinance {
    flex: 1;
    text-align: center;
    margin-left: 302px;
  }
}
</style>
