<template>
  <div class="bg">
    <div class="menu-bar-wrapper">
      <div class="main">
        <div class="menu-bar">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-bar"
            mode="horizontal"
            @select="handleTopMenuSelect"
          >
            <el-menu-item index="1">企业财税分析</el-menu-item>
            <el-menu-item index="2">财税风险详情</el-menu-item>
            <!-- <el-menu-item index="3" v-show="isUserBillList.indexOf(isUserBill) > -1"
              >发票查询</el-menu-item
            >
            <el-menu-item index="4" v-if="!isInShare">报表查询</el-menu-item> -->
          </el-menu>
          <div style="display: flex; align-items: center">
            <div
              @click="deleteBill"
              class="deleteBill"
              v-if="activeIndex == '3' && deleteType != 1"
            >
              删除发票
            </div>
            <!-- <button
              class="btn btn-primary btn-share"
              @click="handleBtnShareClick"
              v-if="!isInShare"
              style="margin-left: 20px"
            >
              转发报告
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div>
      <keep-alive>
        <FinancialAnalysis v-if="activeIndex == '1'"></FinancialAnalysis>
        <resultFinance
          :anchor="detailedAnchor"
          v-if="activeIndex == '2'"
          :hide-share="true"
        ></resultFinance>
        <!-- <bill
          v-if="activeIndex == '3' && isUserBillList.indexOf(isUserBill) > -1"
          ref="bill"
          @deleteBillType="deleteBillType"
        ></bill>
        <search-table v-if="activeIndex == '4'"></search-table> -->
      </keep-alive>
    </div>
    <ThreeYearShowShareCode
      ref="ThreeYearShowShareCode"
      :uuid="$route.params.uuid"
    ></ThreeYearShowShareCode>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import FinancialAnalysis from "@/pages/Result/ThreeYearResult/financialAnalysis/FinancialAnalysis";

import bill from "@/pages/Result/ThreeYearResult/bill/detail";
import searchTable from "@/pages/Result/ThreeYearResult/searchTable/table";

import resultFinance from "@/pages/Result/ThreeYearResult/result/result";

import { deleteBill, getInvoiceAccess } from "@/api/api";
import ThreeYearShowShareCode from "@/pages/Result/ThreeYearResult/components/ThreeShowShareCodeDialog";
import { isInShare } from "@/utils/share";

export default {
  components: {
    FinancialAnalysis,
    bill,
    searchTable,
    resultFinance,
    ThreeYearShowShareCode,
  },
  name: "ThreeYearResult",
  data() {
    return {
      activeIndex: sessionStorage.getItem("threeActiveIndex") || "1",
      detailedAnchor: "",
      deleteType: 1,
      isUserBill:
        JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).id,
      isUserBillList: [11761, 11542, 11828, 11354, 11641, 11329, 11836], //11761银行客户, 11542临平, 11828 2023/1/6客户   11354测试环境, 11641正式环境, 11329志宏测试环境 后面三个用于测试
    };
  },
  mounted() {
    this.$eventBus.$on("switchToDetailTab", (data) => {
      const { anchor } = data;
      this.activeIndex = "2";
      this.detailedAnchor = anchor;
    });

    //
    this.getTaxPaymentType();
  },
  methods: {
    handleBtnShareClick() {
      this.$refs["ThreeYearShowShareCode"].dialogVisible = true;
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
      window.scrollTo(0, 0);
      this.detailedAnchor = "";
      sessionStorage.setItem("threeActiveIndex", key);
    },
    //
    deleteBill() {
      this.$confirm(
        "发票删除后将不再显示发票信息，且无法恢复只能重新授权获取，是否删除？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteBill(this.$route.params.uuid).then((res) => {
            this.$message({
              message: res.message,
              type: "success",
            });
            if (res.code == 200) {
              this.$refs.bill.getCurrentData();
              this.$refs.bill.getgetDataDeleteText();
            }
          });
        })
        .catch(() => {});
    },
    deleteBillType(data) {
      this.deleteType = data;
    },
    // 查看是否存在错误数据  接口有点慢 无法在detect组件调用 只能放这慢慢调用等结果
    getTaxPaymentType() {
      getInvoiceAccess(this.$route.params.uuid).then((res) => {
        if (res.data.showData && res.data.text) {
          this.$notify({
            title: "警告",
            message: res.data.text,
            type: "warning",
            duration: 0,
          });
        }
        if (!res.data.showData) {
          this.$router.push({
            path: "/test/test-noData",
          });
        }
      });
    },
  },
  computed: {
    isInShare() {
      return isInShare(this.$route);
    },
  },
};
</script>

<style lang="stylus" scoped>
.bg
  background: #f6f8fa;
  padding-top: 56px;

.menu-bar-wrapper
  position: fixed;
  z-index: 200;
  top: 0;
  width: 100%
  height: 56px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);

  .menu-bar
    display flex
    justify-content space-between
    align-items center

    >>> .el-menu--horizontal
      border:0

      .el-menu-item
        padding: 0px ;
        font-size: 16px;
        font-weight: 600;
        color: #4E5969;
        margin 0 25px
        height 56px;
        line-height 56px

      .el-menu-item.is-active
        border-bottom: 3px solid #165DFF;
        color: #165DFF;

    .btn-share
      padding 0
      width: 84px;
      height: 28px;
      background: #165DFF;
      border-radius: 2px;

.deleteBill {
  color: #165DFF;
  cursor: pointer;
}
</style>
