<template>
  <div class="main section">
    <div class="section-title">
      <div class="titleIcon"></div>
      <div class="title">成本费用分析</div>
    </div>
    <div class="toolbar">
      <el-radio-group v-model="activeIndex" @change="costInit">
        <el-radio-button :label="index" v-for="(year, index) in costDataYearChart" :key="index"
          >{{ index }}年
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart-wrapper" v-if="costData.total != 0.0 && costData.total != '0.00'&&costData.total != 0 && costData.total != '0'">
      <Pie :data="costData" :key="activeIndex" width="1060px" height="474px"></Pie>
    </div>
    <div class="chart-wrapper" v-else-if="costData.total == 0.0 || costData.total == '0.00'||costData.total == 0||costData.total == '0'">
      <div class="IllegalInformation" style="min-height: 280px">
        <img class="backImgNull" src="../../../../../assets/util/无数据插画.png" alt="" />
        <div class="Illegal">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const base_content = process.env.VUE_APP_API_ROOT;
import Pie from "@/pages/ResultEditor/components/InvoiceIndex/components/costExpensesPieTwo";
export default {
  name: "costExpenses",
  components: {
    Pie,
  },
  data() {
    return {
      activeIndex: "",
      costData: [],
      costDataYearChart: [],
      uuid: this.$route.params.uuid,
    };
  },
  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      const _this = this;
      axios
        .get(`${base_content}/open/boss/report/finance/costAnalysis?uuid=${_this.uuid}`)
        .then(function (res) {
          if (res.data.code == 200) {
            _this.costDataYearChart = res.data.data.yearChart;

            let itemList = [];
            for (var item in res.data.data.yearChart) {
              itemList.push({ year: item });
            }
            if (
              _this.activeIndex == null ||
              _this.activeIndex == "" ||
              _this.activeIndex == "0" ||
              _this.activeIndex == 0
            ) {
              _this.activeIndex = itemList[0].year;
              _this.costData = res.data.data.yearChart[itemList[0].year];
            }
          }
        });
    },
    costInit(tab) {
      const _this = this;
      _this.costData = _this.costDataYearChart[tab];
      _this.load();
    },
  },
};
</script>

<style lang="stylus" scoped>
.Illegal{
  margin: 0 502px 60px
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 22px;
}
.IllegalInformation{
  background: #F1F6FE;
  border-radius: 4px 4px 4px 4px;
  margin-top: 20px;
  .backImgNull{
    margin: 60px 460px 0;
  }
}
.table-wrapper
.section{
  width: 1100px;
  background: #FFFFFF;
  padding:20px;
  border-radius: 8px 8px 8px 8px;
  box-sizing: border-box
}

.titleIcon
    width: 4px;
    height: 20px;
    background: #2663F6;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-right: 20px
.section-title
  height: 22px;
  color: #2663F6;
  line-height: 20px;
  display flex
  border-bottom 1px solid #e6e6e6;
  padding-bottom 18px
  .title
    font-size: 18px;
    font-weight: 600;
//年份
>>> .el-radio-button {
  margin-right 16px

  .el-radio-button__inner {
    border-radius: 2px;
    padding 6px 16px
    border 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}
>>>.el-radio-button.is-active .el-radio-button__inner{
  background: #F1F6FE;
  color:#2663F6;
  padding:8px 16px;
  border-radius: 4px;
}
>>>.el-radio-button .el-radio-button__inner{
  background: #F6F6F6;
  border-radius: 4px;
  padding:8px 16px;
  color:#1D2129
}
.radio{
  margin-bottom:16px
}
>>>.el-radio-button .el-radio-button__inner:hover{
  background:#F1F6FE
  }
.toolbar
  margin-top 20px
  display flex
  justify-content space-between
  align-items center
</style>
