<template>
  <div class="main section" v-if="loaded">
    <div class="title-wrapper">
      <div>
        <div class="title">财税风险检测报告-{{ data.reportedition }}</div>
        <div class="titleCompanyName">{{ data.companyName }}</div>
      </div>
      <div><img src="../../../../assets/插图.png" alt="" /></div>
      <div class="releaseTable">
        <div class="release-date">报告时间：{{ data.exeDate }}</div>
        <!-- <div class="release-date">数据区间：{{ data.estiblishTime }}至{{ data.approvedTime }}</div> -->
        <div class="release-date">数据区间：{{ period }}</div>
      </div>
    </div>
    <!-- <div class="company-name">
      {{ data.companyName }}
    </div> -->
    <div class="contentTitle">
      <div class="circleProgress">
        <template>
          <!-- <div class="demo-progress">
            <el-progress
              :width="172"
              type="circle"
              :percentage="finalScoreValue"
              :format="format"
              :stroke-width="14"
              :color="customColor"
            />
          </div> -->
          <div class="jindutiao">
            <el-progress
              :width="172"
              type="circle"
              :percentage="finalScoreValue"
              :show-text="false"
              :stroke-width="14"
              :color="customColor"
            ></el-progress>
            <div class="jintutiaospan">
              <div>{{ finalScoreValue }}</div>
              <div>综合评分</div>
            </div>
          </div>
        </template>
      </div>
      <div class="basic-infos">
        <div class="basic-info-item">
          <span class="borderLabel"></span>
          <div class="label">所属行业</div>
          <div class="value">{{ data.hymc }}</div>
        </div>
        <div class="basic-info-item">
          <span class="borderLabel"></span>
          <div class="label">注册资本</div>
          <div class="value">{{ data.regCapital }}</div>
        </div>
        <div class="basic-info-item">
          <span class="borderLabel" style="border: none"></span>
          <div class="label">纳税人识别号</div>
          <div class="value">{{ data.taxNo }}</div>
        </div>

        <div class="basic-info-item">
          <span class="borderLabel"></span>
          <div class="label">纳税人资质</div>
          <div class="value">
            {{ data.taxpayerQualificationType ? data.taxpayerQualificationType : "-" }}
          </div>
        </div>
        <div class="basic-info-item">
          <span class="borderLabel"></span>
          <div class="label">实收资本</div>
          <div class="value">{{ data.actualCapital }}</div>
        </div>

        <div class="basic-info-item">
          <span class="borderLabel" style="border: none"></span>
          <div class="label">进出口信用</div>
          <div class="value">{{ data.managementCategory }}</div>
        </div>
        <div class="basic-info-item">
          <span class="borderLabel"></span>
          <div class="label">成立日期</div>
          <div class="value">{{ data.estiblishTime }}</div>
        </div>
        <div class="basic-info-item">
          <span class="borderLabel"></span>
          <div class="label">核准日期</div>
          <div class="value">{{ data.approvedTime }}</div>
        </div>
        <div class="basic-info-item">
          <span class="borderLabel" style="border: none"></span>
          <div class="label">参保人数</div>
          <div class="value">{{ data.socialStaffNum ? data.socialStaffNum : "-" }}</div>
        </div>
        <!--                  <template v-if="data.managementCategory!='无'">-->
        <!--                    <div class="basic-info-item">报关有效期：{{ data.validityDate }}</div>-->
        <!--                    <div class="basic-info-item">海关注销标志：{{ data.status}}</div>-->
        <!--                  </template>-->
      </div>
    </div>
  </div>
  <div class="main section" v-else>
    <BigPowerLoading height="210px"></BigPowerLoading>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import { getCompanyInfo, getCompositeScoreFun, getFinancialRisk } from "@/api/threeYears";
export default {
  name: "Summary",
  data() {
    return {
      data: {},
      loaded: false,
      customColor: [{ color: "#2663F6" }],
      finalScoreValue: null,
      period: "",
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyInfo();
    this.loaded = true;
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({
        programId: this.$route.params.uuid,
        desensitizationStatus: this.$route.query.desensitization,
      });
      this.data = res.data;
      const res2 = await getCompositeScoreFun({ programId: this.$route.params.uuid });
      this.finalScoreValue = res2.data.finalScore * 1;

      const res3 = await getFinancialRisk({
        programId: this.$route.params.uuid,
        grade: "",
      });
      this.period = res3.data.data.period;
    },
    //方法
    format(percentage) {
      return percentage + "\n综合评分";
    },
  },
};
</script>

<style lang="stylus" scoped>
.title-wrapper
  display flex
  align-items center
  justify-content space-between
  height: 108px;
  background: #2663F6;
  border-radius: 8px 8px 0px 0px;
  padding: 24px 20px;
  box-sizing: border-box;
  overflow: hidden;
  .title
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
  .release-date
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    font-size: 16px;
    margin-left: 20px

.company-name
  font-size: 24px;
  font-weight: 600;
  color: #1D2129;
  line-height: 33px;
  margin-top 16px
  margin-bottom 24px

.basic-infos
  margin-top 22px
  display flex;
  flex-wrap wrap;
  height:230px;

  .basic-info-item
    width 33.3%
    display: flex;
    flex-direction: column;
    align-items center
    //border-right:1px solid #C9CDD4;

    .label
      font-size: 14px;
      color: #86909C;
    .value
      font-size: 14px;
      color: #1D2129;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      margin:10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 220px;


.section{
  width: 1100px;
  height: 418px;
  background: #FFFFFF;
  border-radius: 9px 9px 8px 8px;
  opacity: 1;
  border: 1px solid #2663F6;
}
.titleCompanyName{
  color: #FFFFFF;
  font-size: 28px;
  font-weight:600
}
.releaseTable{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.contentTitle{
  display: flex
  .circleProgress{

  }
  .demo-progress{
    width:172px;
    margin 70px 70px
  }
}
/deep/ .el-progress__text {
  white-space: pre;
}
>>> .el-progress__text{
  font-size: 25.1111px;
  font-weight: bold;
  color: #2663F6;
}
.borderLabel{
  height: 24px;
  border-left: 1px solid #c9cdd4;
  position: relative;
  left: 132px;
  top: 38px;
}
.jindutiao{
  position: relative;
  text-align: center;
  width: 172px;
  height: 172px;
  margin 70px 70px
  }
  .jintutiaospan{
    position: absolute;
    color: #2663F6;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;

  }
.jintutiaospan>div:first-child{
  font-size: 42px;
  font-family: DIN Alternate-Bold, DIN Alternate;
  font-weight: bold;
  text-align: center;
  }
</style>
