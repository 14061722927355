<template>
  <div>
    <div class="search-table-content">
      <div class="table-type">
        <div class="type-checkList">展示内容：</div>
        <div class="type-checkList-group">
          <div class="type-tooltip-lrb">
            <el-tooltip
              class="item"
              effect="dark"
              placement="right-start"
              :open-delay="300"
            >
              <div slot="content">在不完整年份为较上年同期的对比</div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <el-checkbox-group v-model="typeCheckList" @change="typeChange">
            <el-checkbox label="显示占比"></el-checkbox>
            <el-checkbox label="显示较上期变动"></el-checkbox>
            <el-checkbox label="只显示重要科目"></el-checkbox>
          </el-checkbox-group>
          <div class="type-tooltip">
            <el-tooltip
              class="item"
              effect="dark"
              placement="right-start"
              :open-delay="300"
            >
              <div slot="content">
                同时满足条件1和2 ：条件1）ABS(占比总资产)>20%
                ；条件2）变动超过±5%科目，或从0到有
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="table-title">
        <div class="unit-dis">单位：元</div>
        <div>利润表</div>
        <div class="unit">单位：元</div>
      </div>
      <div class="maxheightLoaing" v-loading="maxheightLoaing">
        <BigPowerTable :data="zcfzbTable" class="table"></BigPowerTable>
      </div>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import { reportCompare, longPeriodFiscalRetry } from "@/api/api";
import BigPowerTable from "big-power-table";
import { cloneDeep } from "lodash";

import tabUpload from "@/pages/Result/ThreeYearResult/bill/tableUpload/upload";

export default {
  data() {
    return {
      activeName: 1,
      tableActive: 1,
      typeRadio: 2,
      typeCheckList: ["显示占比", "显示较上期变动"],
      ZcfzbDataList: null,
      ZcfzbZqList: null,
      table: null,
      zcfzbTable: {
        header: [],
        body: [],
      },
      copyBody: [],
      copyHeader: [],
      tableType: "CW_ZCFZB", // CW_ZCFZB
      tableTypeList: ["CW_ZCFZB", "CW_LRB"],
      zqName: this.$route.query.zq,
      uuid: this.$route.params.uuid,
      maxheightLoaing: true,
    };
  },

  components: {
    BigPowerTable,
    tabUpload,
  },

  mounted() {
    this.getZcfzbDataList();
  },

  methods: {
    retryReport() {
      this.$confirm("是否重新生成报告?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          longPeriodFiscalRetry(this.uuid).then((res) => {
            if (res.code == 200) {
              this.$router.push({
                name: "test-log",
              });
            }
          });
        })
        .catch(() => {});
    },
    getNewTableData() {
      this.getZcfzbDataList();
    },
    typeRadioChange() {
      this.tableType = "CW_LRB";
      this.getZcfzbDataList();
    },
    handleClick() {},

    getZcfzbDataList() {
      reportCompare(this.uuid, "CW_LRB", this.zqName).then((res) => {
        this.maxheightLoaing = false;
        this.ZcfzbDataList = res.data.itemResultList;
        this.ZcfzbZqList = res.data.zqList;
        this.zcfzbTable.header = [
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 3, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 3, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 3, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
          ],
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
          ],
        ];
        this.copyHeader = this.zcfzbTable.header;
        this.sortTable();
        this.typeChange();
      });
    },

    sortTable() {
      for (let i = 0; i < this.ZcfzbDataList.length; i++) {
        // 赋值
        this.zcfzbTable.body[i] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        this.zcfzbTable.body[i][0].content = this.ZcfzbDataList[i].itemName;
        this.zcfzbTable.body[i][1].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[0]
        ].itemValue;
        this.zcfzbTable.body[i][2].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[0]
        ].percent;
        this.zcfzbTable.body[i][3].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[0]
        ].range;
        this.zcfzbTable.body[i][4].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[1]
        ].itemValue;
        this.zcfzbTable.body[i][5].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[1]
        ].percent;
        this.zcfzbTable.body[i][6].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[1]
        ].range;
        this.zcfzbTable.body[i][7].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[2]
        ].itemValue;
        this.zcfzbTable.body[i][8].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[2]
        ].percent;
        this.zcfzbTable.body[i][9].content = this.ZcfzbDataList[i].map[
          this.ZcfzbZqList[2]
        ].range;

        //对其方式
        this.zcfzbTable.body[i][0].align = "left";
        this.zcfzbTable.body[i][1].align = "right";
        this.zcfzbTable.body[i][2].align = "right";
        this.zcfzbTable.body[i][3].align = "right";
        this.zcfzbTable.body[i][4].align = "right";
        this.zcfzbTable.body[i][5].align = "right";
        this.zcfzbTable.body[i][6].align = "right";
        this.zcfzbTable.body[i][7].align = "right";
        this.zcfzbTable.body[i][8].align = "right";
        this.zcfzbTable.body[i][9].align = "right";

        // 只显示重要科目
        this.zcfzbTable.body[i][0].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][1].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][2].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][3].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][4].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][5].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][6].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][7].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][8].importTag = this.ZcfzbDataList[i].importTag;
        this.zcfzbTable.body[i][9].importTag = this.ZcfzbDataList[i].importTag;

        this.zcfzbTable.body[i][0].tip = this.ZcfzbDataList[i].note;

        // 设置筛选项
        this.zcfzbTable.body[i][0].rows = 0; // 0 标题 数值   1占比    2变动
        this.zcfzbTable.body[i][1].rows = 0;
        this.zcfzbTable.body[i][2].rows = 1;
        this.zcfzbTable.body[i][3].rows = 2;
        this.zcfzbTable.body[i][4].rows = 0;
        this.zcfzbTable.body[i][5].rows = 1;
        this.zcfzbTable.body[i][6].rows = 2;
        this.zcfzbTable.body[i][7].rows = 0;
        this.zcfzbTable.body[i][8].rows = 1;
        this.zcfzbTable.body[i][9].rows = 2;
      }

      this.copyBody = cloneDeep(this.zcfzbTable.body);
    },
    typeChange() {
      if (
        this.typeCheckList.includes("显示占比") &&
        this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = this.copyHeader;
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows != 5;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
      if (
        this.typeCheckList.includes("显示占比") &&
        !this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = [
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
          ],
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "占比",
              align: "center",
            },
          ],
        ];
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows != 2;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
      if (
        !this.typeCheckList.includes("显示占比") &&
        this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = [
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[0],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 2, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              align: "center",
            },
          ],
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },

            {
              content: "数值",
              align: "center",
            },
            {
              content: "较上期变动",
              align: "center",
            },
          ],
        ];
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows != 1;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
      if (
        !this.typeCheckList.includes("显示占比") &&
        !this.typeCheckList.includes("显示较上期变动")
      ) {
        this.zcfzbTable.header = [
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: this.ZcfzbZqList[0],
              colspan: 1, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[1],
              colspan: 1, //这个数用变量控制
              align: "center",
            },
            {
              content: this.ZcfzbZqList[2],
              colspan: 1, //这个数用变量控制
              align: "center",
            },
          ],
          [
            {
              content: "项目",
              align: "center",
              rowspan: 2, //这个数用变量控制
              tip: "本表适配企业会计准则	、企业会计制度、小企业会计准则",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
            {
              content: "数值",
              align: "center",
            },
          ],
        ];
        for (let i = 0; i < this.copyBody.length; i++) {
          this.zcfzbTable.body[i] = this.copyBody[i].filter((item) => {
            return item.rows == 0;
          });
        }
        this.checkImportTag(this.zcfzbTable.body);
      }
    },
    checkImportTag(items) {
      if (this.typeCheckList.includes("只显示重要科目")) {
        for (let i = 0; i < items.length; i++) {
          this.zcfzbTable.body = items.filter((item) => {
            return item[0].importTag;
          });
        }
      } else {
        for (let i = 0; i < items.length; i++) {
          this.zcfzbTable.body = items.filter((item) => {
            return item[0];
          });
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.search-table {
  width: 1240px;
  background: #FFFFFF;
  box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.07);
  margin: 24px auto 30px;

  .search-table-tab {
    position: relative;
    padding: 16px 20px 0 20px;

    .search-table-retry {
      position: absolute;
      width: 116px;
      line-height: 26px;
      height: 28px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #165DFF;
      text-align: center;
      color: #165DFF;
      right: 20px;
      top: 23px;
      font-size: 14px;
      cursor: pointer;
      z-index: 1001;
      box-sizing: border-box;
    }
  }

  >>> .el-tabs__nav-scroll {
    // padding-left: 20px;
  }

  >>> .el-tabs__item.is-active {
    color: #1D2129;
    font-weight: 600;
  }

  >>> .el-tabs__item {
    color: #86909C;
    font-size: 16px;
  }

  >>> .el-tabs__item:hover {
    color: #165DFF;
  }

  .search-table-content {
    padding: 0 20px 20px 20px;

    .tab {
      display: flex;
      align-items: center;

      .table {
        width: 88px;
        height: 32px;
        line-height: 32px;
        background: #FFFFFF;
        border-radius: 2px;
        text-align: center;
        color: #86909C;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 18px;
      }

      .table-active {
        background: rgba(22,93,255,0.1);
        border-radius: 2px;
        color: #165DFF;
        font-weight: 600;
      }
    }

    .table-type {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      color: #86909C;
      font-size: 14px;

      >>> .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #165DFF;
      }

      .type-checkList {
        margin-left: 302px;
      }

      .type-checkList-group {
        position: relative;
        display: flex;
        align-items: center;

        .type-tooltip-lrb {
          position: absolute;
          left: 234px;
          top: 1px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }

    .table-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 29px 30px 14px 30px;
      font-weight: 600;
      color: #1D2129;
      line-height: 28px;

      .unit {
        font-size: 14px;
        font-weight: 400;
        color: #86909C;
      }

      .unit-dis {
        color: transparent;
      }
    }
  }
}

.type-tooltip {
  padding: 0 6px;
  font-size: 16px;
  cursor: pointer;
}

.maxheightLoaing {
  min-height: 400px;
}
</style>
