<template>
  <div>
    <Summary></Summary>
    <financialRisk></financialRisk>
    <KeyFinanceRatio></KeyFinanceRatio>
    <UpDownCustomer></UpDownCustomer>
    <costExpenses></costExpenses>
    <historicalSituation></historicalSituation>
    <PurchaseAndSale></PurchaseAndSale>
    <FinanceAndDuban></FinanceAndDuban>
    <HistoryTaxCondition></HistoryTaxCondition>
  </div>

</template>

<script>
import Summary from "@/pages/Result/ThreeYearResult/financialAnalysis/Summary";
import "@/pages/Result/ThreeYearResult/global.styl"
import financialRisk from "@/pages/Result/ThreeYearResult/financialAnalysis/FinancialRisk";
import KeyFinanceRatio from "@/pages/Result/ThreeYearResult/financialAnalysis/KeyFinanceRatio";
import UpDownCustomer from "@/pages/Result/ThreeYearResult/financialAnalysis/UpDownCustomer";
import costExpenses from "./costexpenses/costExpenses";
import historicalSituation from "./historicalSituation/historicalSituation";
import PurchaseAndSale
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSale";
import FinanceAndDuban
  from "@/pages/Result/ThreeYearResult/financialAnalysis/financeAndDuban/FinanceAndDuban";
import HistoryTaxCondition
  from "@/pages/Result/ThreeYearResult/financialAnalysis/historyTaxCondition/HistoryTaxCondition";
export default {
  components: {
    HistoryTaxCondition,
    Summary,
    financialRisk,
    KeyFinanceRatio,
    UpDownCustomer,
    PurchaseAndSale,
    FinanceAndDuban,costExpenses,historicalSituation
  },
  name: "FinancialAnalysis",
}
</script>

<style lang="stylus" scoped>


</style>
